import React from "react";

import { Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { userState } from "../../redux/feature/userSlice";
import { useSelector } from "react-redux";
import LoginAlert from "../../components/LoginAlert";

const SubMenu = ({
  handleChange,
  value,
  item,
  submitYourEvent,
  navigate,
  exhibit,
  collection,
  support,
}) => {
  const { userData } = useSelector(userState);
  return (
    <>
      <Grid
        item
        display="flex"
        direction={"row"}
        alignItems="center"
        backgroundColor="white"
        justifyContent={"center"}
        sx={{ boxShadow: 1 }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="black"
          TabIndicatorProps={{
            style: {
              backgroundColor: "black",
            },
          }}
        >
          {exhibit ? (
            <>
              <Tab
                label="Now Showing"
                sx={{
                  color: "black",
                  margin: "0 1rem",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginLeft: "100px",
                }}
                onClick={() => navigate("/exhibition-event")}
              />
              <Tab
                label="Incoming"
                sx={{
                  color: "black",
                  margin: "0 1rem",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                onClick={() => navigate("/exhibition-all")}
              />
              <Tab
                label="past"
                sx={{
                  color: "black",
                  margin: "0 1rem",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                onClick={() => navigate("/exhibition-view")}
              />
            </>
          ) : support ? (
            <>
              <Tab
                label="Membership"
                sx={{
                  color: "black",
                  margin: "0 1rem",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                onClick={() => navigate("/login")}
              />
              <Tab
                label="Campaign"
                sx={{
                  color: "black",
                  margin: "0 1rem",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                onClick={() => {
                  window.open("https://www.loket.com/", "__blank");
                  window.location.reload();
                }}
              />
              <Tab
                label="Donate"
                sx={{
                  color: "black",
                  margin: "0 1rem",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                onClick={() => {
                  window.open("https://www.loket.com/", "__blank");
                  window.location.reload();
                }}
              />
            </>
          ) : submitYourEvent ? (
            <>
              <Tab
                label="Submit Your Event"
                sx={{
                  color: "black",
                  margin: "0 1rem",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                onClick={() => navigate("/submit-event")}
              />
              <Tab
                label="Admission"
                sx={{
                  color: "black",
                  margin: "0 1rem",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                onClick={() => navigate("/admission")}
              />
              <Tab
                label="Getting Here"
                sx={{
                  color: "black",
                  margin: "0 1rem",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                onClick={() => navigate("/getting-here")}
              />
              <Tab
                label="Venue Map"
                sx={{
                  textTransform: "none",
                  color: "black",
                  margin: "0 1rem",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                onClick={() => navigate("/venue")}
              />
              <Tab
                label="Shop"
                sx={{
                  color: "black",
                  margin: "0 1rem",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              />
            </>
          ) : null}
        </Tabs>
      </Grid>
    </>
  );
};

export default SubMenu;
