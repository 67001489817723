import React, { useState } from "react";
import { Box, Collapse, Grid, Link, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { userState } from "../../redux/feature/userSlice";

function ItemNavbar({ item, index, itemActive, setItemActive }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { userData } = useSelector(userState);

  const handleToggleOpen = () => {
    setItemActive();
    if (open && itemActive !== index) return;
    setOpen(!open);
  };

  const handleLinkClick = (item) => {
    if (item.title === "Collection") {
      window.open("https://www.loket.com", "__blank");
      window.location.reload();
    } else if (item.title === "Donate") {
      window.open("https://www.loket.com", "__blank");
      window.location.reload();
    } else if (item.title === "Campaign") {
      window.open("https://www.loket.com", "__blank");
      window.location.reload();
    } else if (item.path) {
      navigate(item.path);
    }
  };

  return (
    <>
      {item.subMenu ? (
        <Link
          onClick={handleToggleOpen}
          sx={{
            color: "neutral.100",
            paddingX: "1rem",
            paddingY: "0.5rem",
            textDecoration: "none",
            fontSize: "0.875rem",
            borderBottom: "2px solid",
            borderColor: "neutral.800",
            fontWeight: 700,
            "&:hover": {
              cursor: "pointer",
              borderBottom: "2px solid white",
            },
          }}
        >
          {item.title}
        </Link>
      ) : (
        <Link
          onClick={() => handleLinkClick(item)}
          sx={{
            color: "neutral.100",
            paddingX: "1rem",
            paddingY: "0.5rem",
            textDecoration: "none",
            fontSize: "0.875rem",
            borderBottom: "2px solid",
            borderColor: "neutral.800",
            fontWeight: 700,
            "&:hover": {
              cursor: "pointer",
              borderBottom: "2px solid white",
            },
          }}
        >
          {item.title}
        </Link>
      )}
      {item.subMenu && (
        <Collapse
          in={open && itemActive === index}
          timeout="auto"
          unmountOnExit
          sx={{
            position: "absolute",
            zIndex: "10",
            top: "4.375rem",
            left: 0,
            width: "100%",
            bgcolor: "neutral.100",
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Grid container>
            <Grid item sm={10} md={8} margin="0 auto">
              <Box
                sx={{
                  width: "100%",
                  height: "3.313rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                {item.subMenu?.map((subItem) =>
                  subItem?.role ? (
                    subItem?.role === userData?.role && (
                      <>
                        <Divider
                          sx={{ height: "1.188rem", bgcolor: "neutral.600" }}
                          orientation="vertical"
                        />
                        <Box flex={1} textAlign="center">
                          <Link
                            sx={{
                              color: "neutral.800",
                              fontSize: "0.75rem",
                              textDecoration: "none",
                              borderBottom: "2px solid",
                              borderColor: "neutral.100",
                              "&:hover": {
                                fontWeight: 700,
                                borderBottom: "2px solid",
                                borderColor: "neutral.800",
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => handleLinkClick(subItem)}
                          >
                            {subItem.title}
                          </Link>
                        </Box>
                      </>
                    )
                  ) : (
                    <Box flex={1} textAlign="center">
                      <Link
                        sx={{
                          color: "neutral.800",
                          fontSize: "0.75rem",
                          textDecoration: "none",
                          borderBottom: "2px solid",
                          borderColor: "neutral.100",
                          "&:hover": {
                            fontWeight: 700,
                            borderBottom: "2px solid",
                            borderColor: "neutral.800",
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => handleLinkClick(subItem)}
                      >
                        {subItem.title}
                      </Link>
                    </Box>
                  )
                )}
              </Box>
            </Grid>
          </Grid>
        </Collapse>
      )}
    </>
  );
}

export default ItemNavbar;
