import React from "react";
import { Box, Divider, Grid, Link, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Logo from "../assets/logotim.png";
import {
  mainFooterLink,
  secondaryFooterLink,
  socialFooterLink,
} from "../utils/constant";

const Footer = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = (state) => {
    setOpen(state);
  };

  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        display="flex"
        direction="column"
        alignItems="center"
        padding="64px 24px"
        marginTop="64px"
        minHeight="400px"
        gap="36px"
        bottom="0"
        sx={{
          borderTop: 1,
          borderColor: "#E0E0E0",
          bgcolor: "background.paper",
        }}
      >
        <Grid
          item
          display="flex"
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          gap="36px"
        >
          {mainFooterLink.map((item) => (
            <Link
              key={item.title}
              sx={{
                cursor: "pointer",
                color: "neutral.800",
                textDecoration: "none",
              }}
              // onClick={() => item.path && navigate(item.path)}
              onClick={() => {
                if (item.path) {
                  navigate(item.path);
                } else if (item.title === "Legal") {
                  handleOpen(true);
                }
              }}
            >
              {item.title}
            </Link>
          ))}
          <Modal
            open={open}
            onClose={() => handleOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                p: 4,
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Lorem Ipsum Placeholder
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, textAlign: "justify" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                posuere cubilia curae; Proin sit amet diam non velit ullamcorper
                fermentum.
              </Typography>
            </Box>
          </Modal>
          <Divider
            sx={{ height: "1.188rem", bgcolor: "neutral.800" }}
            orientation="vertical"
          />
          {socialFooterLink.map((item) => (
            <Link
              key={item.link}
              sx={{
                cursor: "pointer",
                color: "neutral.800",
                textDecoration: "none",
              }}
              href={item.link}
            >
              <img src={item.image} alt="" />
            </Link>
          ))}
        </Grid>
        <Grid item>
          <img src={Logo} alt="" />
        </Grid>
        <Grid item display="flex" direction="row" flexWrap="wrap" gap="48px">
          {secondaryFooterLink.map((item) => (
            <Link
              key={item.title}
              sx={{
                cursor: "pointer",
                color: "neutral.800",
                textDecoration: "none",
              }}
              onClick={() => item.path && navigate(item.path)}
            >
              {item.title}
            </Link>
          ))}
        </Grid>
        <Grid item>Copyright © 2023 Inagri. All Rights Reserved.</Grid>
      </Grid>
    </>
  );
};

export default Footer;
