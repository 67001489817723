import React from "react";
import { Icon } from "@iconify/react";
import { Box, Card, Divider, Typography } from "@mui/material";
import dayjs from "dayjs";

// component
import StatusTag from "../Elements/StatusTag";
import MainButton from "../Elements/MainButton";

function EventCard({
  title,
  bookingId,
  date,
  proposalStatus,
  postStatus,
  onShowDetail,
  eventType,
  startDate,
  endDate,
}) {
  const postStatusTag = () => {
    switch (postStatus) {
      // case "Data Belum Ada":
      //   return "danger";
      // case "Data Belum Ada":
      //   return "danger";
      case "Menunggu Approval Proposal Event":
        return "danger";
      case "Disetujui":
        return "success";
      default:
        return "danger";
    }
  };
  const proposalStatusTag = () => {
    switch (proposalStatus) {
      case "approve-plan-visit":
        return "success";
      case "reject-plan-visit":
        return "danger";
      default:
        return "basic";
    }
  };

  return (
    <Card sx={{ padding: "1rem", borderRadius: 0 }}>
      <Typography
        sx={{ fontSize: "1rem", fontWeight: 600, color: "neutral.600" }}
      >
        Booking ID : {bookingId}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "start",
          marginTop: "0.5rem",
        }}
      >
        <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
          {title}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
        <Typography variant="caption" color="neutral.600">
          {eventType == 1 ? "Exhibitor" : "Event"}
        </Typography>
        <Divider
          sx={{ height: "1.5rem", bgcolor: "neutral.600", marginX: "1rem" }}
          orientation="vertical"
        />
        <Box sx={{ display: "flex", gap: "0.688rem", alignItems: "center" }}>
          <Icon
            icon="mdi:calendar-range"
            color="#828282"
            height="1.25rem"
            width="1.25rem"
          />
          <Typography variant="caption" color="neutral.600">
            {`${dayjs(startDate).format("dddd, D MMMM YYYY")} - ${dayjs(
              endDate
            ).format("dddd, D MMMM YYYY")}`}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "0.688rem",
          gap: "0.5rem",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
              Proposal Event
            </Typography>
            <StatusTag variant={proposalStatusTag()}>
              {proposalStatus === "approve-plan-visit"
                ? "Disetujui"
                : proposalStatus === "reject-plan-visit"
                ? "Ditolak"
                : "Menunggu Persetujuan"}
            </StatusTag>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginTop: "0.688rem",
              gap: "1rem",
            }}
          >
            <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
              Post Event
            </Typography>
            <StatusTag variant={postStatusTag()}>{postStatus}</StatusTag>
          </Box>
        </Box>
        <MainButton
          variant="outlined"
          sx={{ width: "fit-content" }}
          onClick={onShowDetail}
        >
          Lihat Detail
        </MainButton>
      </Box>
    </Card>
  );
}

export default EventCard;
