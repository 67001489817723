import React from "react";
import { Grid, Box, Button, TextField } from "@mui/material";

import News1 from "../assets/rename2.png";
import News2 from "../assets/gambar1.png";
import News3 from "../assets/News3.JPG";
import Filter from "../components/filterEx";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <Grid display={"flex"} direction="column" gap={3}>
        <Grid
          display={"flex"}
          direction="column"
          item
          padding="1rem 7rem"
          gap={4}
        >
          <Grid
            container
            display={"flex"}
            flexDirection="column"
            alignItems={"flex-start"}
            padding="16px"
            gap="8px"
            width={"500px"}
            height="1900px"
          >
            <Grid item>
              <Filter />
            </Grid>
            <Grid item>
              <Grid
                container
                fontWeight="500"
                fontFamily={"Poppins"}
                lineHeight="150%"
                fontStyle={"normal"}
                fontSize="24px"
                color="#1F1F1F"
                width={"497px"}
                height={"36px"}
              >
                <Grid item>Date: 12 Februari 2023 - 28 Februari 2023</Grid>
              </Grid>
              <Grid
                container
                display={"flex"}
                flexDirection={"row"}
                alignItems="center"
                padding={"0px"}
                width="315"
                height={"48px"}
              >
                <Grid
                  item
                  fontWeight="500"
                  fontFamily={"Plus Jakarta Sans"}
                  lineHeight="250%"
                  fontStyle={"normal"}
                  fontSize="20px"
                  color="#1F1F1F"
                  width={"150px"}
                  height={"50px"}
                >
                  Exhibitions (24)
                </Grid>
                <Grid item>
                  <Button
                    style={{
                      color: "#FF723D",
                      fontWeight: "200",
                      fontSize: "20px",
                      cursor: "pointer",
                      textTransform: "none",

                      top: "30%",
                    }}
                    disableElevation
                  >
                    View Full Calender {">"}
                  </Button>
                </Grid>
              </Grid>

              <Grid item display={"flex"} direction="column" gap={2}>
                <Grid item display={"flex"} direction="row" gap={2}>
                  <Grid item>
                    <Box
                      sx={{
                        width: "245px",
                        height: "150px",
                        backgroundImage: `url(${News1})`,
                        backgroundSize: "cover",
                      }}
                    ></Box>
                  </Grid>
                  <Grid item display={"flex"} direction="column">
                    <Grid
                      item
                      fontWeight="700"
                      fontSize="16px"
                      width={"422px"}
                      height="26px"
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      Animakini X Ciffest 2023
                    </Grid>
                    <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                      20-22 Februari 2023
                    </Grid>
                    <Grid
                      item
                      fontWeight="400"
                      fontSize="10px"
                      fontFamily={"Plus Jakarta Sans"}
                      lineHeight="160%"
                      fontStyle="normal"
                      color={"grey"}
                    >
                      Experience Sir Isaac Julien’s video installation exploring
                      the life and legacy
                    </Grid>
                    <Grid container>
                      <Box
                        sx={{
                          backgroundColor: "#FFF1EB",
                          color: "#FF723D",
                          borderRadius: "50%",
                          padding: "17.5444px 17px",
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                          marginTop: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/plan-booking")}
                      >
                        Free
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item display={"flex"} direction="row" gap={2}>
                  <Grid item>
                    <Box
                      sx={{
                        width: "245px",
                        height: "150px",
                        backgroundImage: `url(${News2})`,
                        backgroundSize: "cover",
                      }}
                    ></Box>
                  </Grid>
                  <Grid item display={"flex"} direction="column">
                    <Grid
                      item
                      fontWeight="700"
                      fontSize="16px"
                      width={"422px"}
                      height="26px"
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      Urang Minang Baralek Gadang
                    </Grid>
                    <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                      28-29 April 2018
                    </Grid>
                    <Grid item fontWeight="500" fontSize="10px">
                      Experience Sir Isaac Julien’s video installation exploring
                      the life and legacy
                    </Grid>
                    <Grid container>
                      <Box
                        sx={{
                          backgroundColor: "#FFF1EB",
                          color: "#FF723D",
                          borderRadius: "50%",
                          padding: "17.5444px 17px",
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                          marginTop: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/plan-booking")}
                      >
                        Free
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item display={"flex"} direction="row" gap={2}>
                  <Grid item>
                    <Box
                      sx={{
                        width: "245px",
                        height: "150px",
                        backgroundImage: `url(${News1})`,
                        backgroundSize: "cover",
                      }}
                    ></Box>
                  </Grid>
                  <Grid item display={"flex"} direction="column">
                    <Grid
                      item
                      fontWeight="700"
                      fontSize="16px"
                      width={"422px"}
                      height="26px"
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      Animakini X Ciffest 2023
                    </Grid>
                    <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                      20-22 Februari 2023
                    </Grid>
                    <Grid item fontWeight="500" fontSize="10px">
                      Experience Sir Isaac Julien’s video installation exploring
                      the life and legacy of
                    </Grid>
                    <Grid container>
                      <Box
                        sx={{
                          backgroundColor: "#FFF1EB",
                          color: "#FF723D",
                          borderRadius: "50%",
                          padding: "17.5444px 17px",
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                          marginTop: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/plan-booking")}
                      >
                        Free
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item display={"flex"} direction="row" gap={2}>
                  <Grid item>
                    <Box
                      sx={{
                        width: "245px",
                        height: "150px",
                        backgroundImage: `url(${News2})`,
                        backgroundSize: "cover",
                      }}
                    ></Box>
                  </Grid>
                  <Grid item display={"flex"} direction="column">
                    <Grid
                      item
                      fontWeight="700"
                      fontSize="16px"
                      width={"422px"}
                      height="26px"
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      Urang Minang Baralek Gadang
                    </Grid>
                    <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                      28-29 April 2018
                    </Grid>
                    <Grid item fontWeight="500" fontSize="10px">
                      Experience Sir Isaac Julien’s video installation exploring
                      the life and legacy of
                    </Grid>
                    <Grid container>
                      <Box
                        sx={{
                          backgroundColor: "#FFF1EB",
                          color: "#FF723D",
                          borderRadius: "50%",
                          padding: "17.5444px 17px",
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                          marginTop: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/plan-booking")}
                      >
                        Free
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item display={"flex"} direction="row" gap={2}>
                  <Grid item>
                    <Box
                      sx={{
                        width: "245px",
                        height: "150px",
                        backgroundImage: `url(${News1})`,
                        backgroundSize: "cover",
                      }}
                    ></Box>
                  </Grid>
                  <Grid item display={"flex"} direction="column">
                    <Grid
                      item
                      fontWeight="700"
                      fontSize="16px"
                      width={"422px"}
                      height="26px"
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      Animakini X Ciffest 2023
                    </Grid>
                    <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                      20-22 Februari 2023
                    </Grid>
                    <Grid item fontWeight="500" fontSize="10px">
                      Experience Sir Isaac Julien’s video installation exploring
                      the life and legacy
                    </Grid>
                    <Grid container>
                      <Box
                        sx={{
                          backgroundColor: "#FFF1EB",
                          color: "#FF723D",
                          borderRadius: "50%",
                          padding: "17.5444px 17px",
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                          marginTop: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/plan-booking")}
                      >
                        Free
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item display={"flex"} direction="row" gap={2}>
                  <Grid item>
                    <Box
                      sx={{
                        width: "245px",
                        height: "150px",
                        backgroundImage: `url(${News2})`,
                        backgroundSize: "cover",
                      }}
                    ></Box>
                  </Grid>
                  <Grid item display={"flex"} direction="column">
                    <Grid
                      item
                      fontWeight="700"
                      fontSize="16px"
                      width={"422px"}
                      height="26px"
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      Urang Minang Baralek Gadang
                    </Grid>
                    <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                      28-29 April 2018
                    </Grid>
                    <Grid item fontWeight="500" fontSize="10px">
                      Experience Sir Isaac Julien’s video installation exploring
                      the life and legacy of
                    </Grid>
                    <Grid container>
                      <Box
                        sx={{
                          backgroundColor: "#FFF1EB",
                          color: "#FF723D",
                          borderRadius: "50%",
                          padding: "17.5444px 17px",
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                          marginTop: "30px",
                          cursor: "pointer",
                        }}
                      >
                        Free
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item display={"flex"} direction="row" gap={2}>
                  <Grid item>
                    <Box
                      sx={{
                        width: "245px",
                        height: "150px",
                        backgroundImage: `url(${News1})`,
                        backgroundSize: "cover",
                      }}
                    ></Box>
                  </Grid>
                  <Grid item display={"flex"} direction="column">
                    <Grid
                      item
                      fontWeight="700"
                      fontSize="16px"
                      width={"422px"}
                      height="26px"
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      Animakini X Ciffest 2023
                    </Grid>
                    <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                      20-22 Februari 2023
                    </Grid>
                    <Grid item fontWeight="500" fontSize="10px">
                      Experience Sir Isaac Julien’s video installation exploring
                      the life and legacy of
                    </Grid>
                    <Grid container>
                      <Box
                        sx={{
                          backgroundColor: "#FFF1EB",
                          color: "#FF723D",
                          borderRadius: "50%",
                          padding: "17.5444px 17px",
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                          marginTop: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/plan-booking")}
                      >
                        Free
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item display={"flex"} direction="row" gap={2}>
                  <Grid item>
                    <Box
                      sx={{
                        width: "245px",
                        height: "150px",
                        backgroundImage: `url(${News1})`,
                        backgroundSize: "cover",
                      }}
                    ></Box>
                  </Grid>
                  <Grid item display={"flex"} direction="column">
                    <Grid
                      item
                      fontWeight="700"
                      fontSize="16px"
                      width={"422px"}
                      height="26px"
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      Animakini X Ciffest 2023
                    </Grid>
                    <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                      20-22 Februari 2023
                    </Grid>
                    <Grid item fontWeight="500" fontSize="10px">
                      Experience Sir Isaac Julien’s video installation exploring
                      the life and legacy of
                    </Grid>
                    <Grid container>
                      <Box
                        sx={{
                          backgroundColor: "#FFF1EB",
                          color: "#FF723D",
                          borderRadius: "50%",
                          padding: "17.5444px 17px",
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                          marginTop: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/plan-booking")}
                      >
                        Free
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item display={"flex"} direction="row" gap={2}>
                  <Grid item>
                    <Box
                      sx={{
                        width: "245px",
                        height: "150px",
                        backgroundImage: `url(${News1})`,
                        backgroundSize: "cover",
                      }}
                    ></Box>
                  </Grid>
                  <Grid item display={"flex"} direction="column">
                    <Grid
                      item
                      fontWeight="700"
                      fontSize="16px"
                      width={"422px"}
                      height="26px"
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      Animakini X Ciffest 2023
                    </Grid>
                    <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                      20-22 Februari 2023
                    </Grid>
                    <Grid item fontWeight="500" fontSize="10px">
                      Experience Sir Isaac Julien’s video installation exploring
                      the life and legacy of
                    </Grid>
                    <Grid container>
                      <Box
                        sx={{
                          backgroundColor: "#FFF1EB",
                          color: "#FF723D",
                          borderRadius: "50%",
                          padding: "17.5444px 17px",
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                          marginTop: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/plan-booking")}
                      >
                        Free
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
