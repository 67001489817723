import React from "react";
import { Grid } from "@mui/material";
import TabsHeader from "./Tabs/TabsHeader";
import SubMenu from "./Tabs/SubMenu";
import LoginAlert from "./LoginAlert";

import { useSelector } from "react-redux";
import { loginAlertState } from "../redux/feature/loginAlertSlice";
import { userState } from "../redux/feature/userSlice";

const NavbarSticky = ({ navigate, value, setValue, handleChangeTabHeader }) => {
  const { isShowing } = useSelector(loginAlertState);

  return (
    <>
      <Grid
        item
        display="flex"
        direction={"column"}
        // gap={2}
        sx={{
          backgroundColor: "black",
          position: "sticky",
          top: "0",
          zIndex: "2000",
          padding: "1rem 0 0 0",
        }}
      >
        <Grid item margin={"0 auto"}>
          <TabsHeader
            navigate={navigate}
            setValue={setValue}
            value={value}
            handleChange={handleChangeTabHeader}
            
          />
        </Grid>
        {value === "Plan Your Visit" ? (
          <SubMenu
            navigate={navigate}
            setValue={setValue}
            submitYourEvent={true}
            value={value}
          />
        ) : value === "Exhibition & Event" ? (
          <SubMenu
            navigate={navigate}
            exhibit={true}
            setValue={setValue}
            value={value}
          />
        ) : value === "Collection" ? (
          <SubMenu navigate={navigate} setValue={setValue} value={value} />
        ) : value === "Join & Support" ? (
          <SubMenu
            navigate={navigate}
            support={true}
            setValue={setValue}
            value={value}
          />
        ) : null}
        {isShowing && <LoginAlert />}
      </Grid>
    </>
  );
};

export default NavbarSticky;
