import React from "react";
import { Grid, Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Aktif from "../assets/online.png";
import Edit from "../assets/Edit.png";
import Hapus from "../assets/hapus.png";
import MainButton from "./Elements/MainButton";
import StatusTag from "./Elements/StatusTag";

// import Incomplete from "../assets/incomplete.png";
// import { Navigate } from "react-router-dom";

const TableBig = ({ total, result, setSection, EditPR }) => {
  //   const { isShowing: isShowingDelete, toggle: toggleDelete } = useModal();

  //     const handleDeleteClick = () => {
  //       // const ids = target_list.map((id) => id.target_id);

  //       // setDataDelete(ids);
  //       toggleDelete();
  //     };
  const tableHeadTotal = [
    "Sales subtotal",
    "Total discount",
    "Charges",
    "Sales Tax",
    "Round-off",
    "Currency",
    "Total",
  ];
  const rows = [
    {
      ID: "1",
      nama: "Ahmad Aziz Nuraidi",
      Email: "Ahmadaziz123@gmail.com ",
      email: "Ahmad123",
      // status:{Aktif}
    },
    {
      ID: "2",
      nama: "Rozak Nurlail",
      Email: "Rozaknurlail123@gmail.com ",
      email: "Rozak123",
    },
    {
      ID: "3",
      nama: "Bani Imanudin",
      Email: "Baniimanudin123@gmail.com ",
      email: "Bani123",
    },
  ];
  const handleEditAccountClick = () => {
    // UserManagementService.getUserById(id).then((response) => {
    //   setData(response?.data?.data);
    //   // return response; i dont need to return anytthing cause i use state
    // });
    // setId(id);
    EditPR();
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FFECEC",
      color: "#FF723D",
      //   border: "1px solid #D12C24",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: "#FF723D",
    },
  }));

  const handleDelete = async (id) => {
    // setLoading(true);
    // try {
    //   await id.forEach((_id) => {
    //     UserManagementService.deleteUser(_id);
    //   });
    //   setTimeout(() => {
    //     setLoading(false);
    //     toggleDelete();
    //     queryClient.invalidateQueries(["data-users"]);
    //   }, 3000);
    // } catch (err) {
    //   window.alert(err.response.data.detail_message);
    //   setLoading(false);
    //   toggleDelete();
    // }
    // setTimeout(queryClient.invalidateQueries(['data-target']), 6000);
  };

  const isLogin = localStorage.getItem("isLogin");

  return (
    <>
      {/* <DeleteModal
        toggle={toggleDelete}
        isShowing={isShowingDelete}
        title="Data"
        action={handleDelete}
        // id={selectedId}
        // loading={loading}
      /> */}
      <Grid
        item
        container
        padding="0 0 8px 0"
        gap="24px"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              {total ? (
                <TableRow>
                  {tableHeadTotal?.map((item) => (
                    <StyledTableCell>{item}</StyledTableCell>
                  ))}
                </TableRow>
              ) : (
                <TableRow>
                  <StyledTableCell style={{ position: "sticky", left: "0" }}>
                    <input
                      style={{ width: "18px", height: "18px" }}
                      type={"checkbox"}
                      name="ID"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">ID</StyledTableCell>
                  <StyledTableCell align="left">Username</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell align="left">Password</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                  <StyledTableCell align="left">Aksi</StyledTableCell>
                </TableRow>
              )}
            </TableHead>
            {total ? (
              <TableBody>
                <TableCell align="left">0.00</TableCell>
                <TableCell align="left">0.00</TableCell>
                <TableCell align="left">0.00</TableCell>
                <TableCell align="left">0.00</TableCell>
                <TableCell align="left">0.00</TableCell>
                <TableCell align="left">IDR</TableCell>
                <TableCell align="left">{result}</TableCell>
              </TableBody>
            ) : (
              <TableBody>
                {rows?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        position: "sticky",
                        left: "0",
                        background: "white",
                      }}
                    >
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type={"checkbox"}
                        name="foo"
                        id={row.id}
                        // onChange={() => selectFunction(row.id)}
                      />
                    </TableCell>
                    <TableCell align="left">{row.ID}</TableCell>
                    <TableCell align="left">{row.nama}</TableCell>
                    <TableCell align="left">{row.Email}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">
                      {isLogin === "superadmin" ? (
                        <StatusTag variant={"success"}>Disetujui</StatusTag>
                      ) : (
                        <img src={Aktif} alt="aktif" />
                      )}
                    </TableCell>

                    <TableCell align="left">
                      <Grid container gap={2} sx={{ width: "200px" }}>
                        <MainButton variant={"contained"}>
                          <img src={Edit} alt="" />
                          <Box marginLeft={"0.5rem"}>Edit</Box>
                        </MainButton>
                        <MainButton variant={"outlined"}>
                          <img src={Hapus} alt="" />
                          <Box marginLeft={"0.5rem"}>Hapus</Box>
                        </MainButton>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default TableBig;
