import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Box } from "@mui/material";
import { Icon } from "@iconify/react";
import MainButton from "../components/Elements/MainButton";

const LoginAlert = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  useEffect(() => {
    if (window.location.pathname === "/submit-event") {
      setTitle("Tertarik untuk mengajukkan event anda?");
    } else {
      setTitle("Tertarik dengan Exhibition/Event ini?");
    }
  }, [window.location.pathname]);

  return (
    <>
      <Grid
        container
        disp
        sx={{ flexDirection: "row", backgroundColor: "white" }}
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
      >
        <Icon icon="uil:user-check" width="32" height="32" />
        <Box sx={{ flexDirection: "column" }}>
          <Typography fontWeight={"700"}>{title}</Typography>
          <Typography color={"#828282"} fontSize={"12px"}>
            Log in dulu buat masuk halaman ini. Ada banyak promo menarik!
          </Typography>
        </Box>
        <Box padding="15px" marginLeft={"24rem"}>
          <MainButton
            variant="outlined"
            sx={{ marginRight: "1rem" }}
            onClick={() => navigate("/login")}
          >
            Login
          </MainButton>
          <MainButton
            variant="contained"
            onClick={() =>
              window.open("https://www.loket.com/register", "__blank")
            }
          >
            Buat Akun
          </MainButton>
        </Box>
      </Grid>
    </>
  );
};

export default LoginAlert;
