import React from "react";

function StatusTag({ children, variant }) {
  const styleTag = () => {
    switch (variant) {
      case "danger":
        return {
          backgroundColor: "#FFF1EB",
          color: "#B43127",
          padding: "0.5rem 1rem",
          fontSize: "0.875rem",
          fontWeight: 700,
        };
      case "success":
        return {
          backgroundColor: "rgb(224, 255, 213)",
          color: "rgb(129, 202, 84)",
          padding: "0.5rem 1rem",
          fontSize: "0.75rem",
          fontWeight: 700,
          border: "0.063rem solid rgb(129, 202, 84)",
          borderRadius: "0.25rem",
          width: "fit-content",
        };
      case "basic":
        return {
          backgroundColor: "#FFF1EB",
          color: "#D14F1E",
          padding: "0.5rem 1rem",
          fontSize: "0.875rem",
          fontWeight: 700,
        };
      default:
        return {
          backgroundColor: "#FFF1EB",
          color: "#D14F1E",
          padding: "0.5rem 1rem",
          fontSize: "0.875rem",
          fontWeight: 700,
        };
    }
  };
  return <div style={styleTag()}>{children}</div>;
}

export default StatusTag;
