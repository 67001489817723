import React, { useState, useEffect } from "react";
import { Grid, Box, Button, Typography } from "@mui/material";
import Logo from "../assets/anim.png";
import Info from "../assets/info.png";
import Pict from "../assets/venue.png";
import Event1 from "../assets/animakini.png";
import Event2 from "../assets/minang.png";
import Event3 from "../assets/pameran.png";
import Event4 from "../assets/pameran2.png";

import Fotter from "../components/Footer";
import { Icon } from "@iconify/react";
import MainButton from "../components/Elements/MainButton";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/MainNavbar";
import TabsHome from "../components/tabsHome";
import { exhibitionLabel } from "../utils/tabsLabel";
import Card from "../components/Exhibitions/Card";

const About = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("Deskripsi");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === "Paket") {
      // console.log("test");
      document
        .getElementById("paket")
        .scrollIntoView({ behavior: "smooth" }, true);
    } else if (value === "Lokasi") {
      document
        .getElementById("location")
        .scrollIntoView({ behavior: "smooth" }, true);
    } else {
      window.scrollTo(0, 0);
    }
  }, [value]);

  return (
    <>
      <Box backgroundColor="white">
        <Grid
          display={"flex"}
          direction="column"
          gap={3}
          backgroundColor="#FFFFFF"
          padding="0px 200px"
        >
          <Navbar />
          <img src={Logo} alt="" />
          <Box display={"flex"} gap={2}>
            <Typography fontWeight={"500"} fontSize={"16px"}>
              Exhibition & Event
            </Typography>
            <Typography fontWeight={"500"} fontSize={"16px"}>
              {">"}
            </Typography>
            <Typography fontWeight={"500"} fontSize={"16px"} color="#828282">
              Animakini X Ciffest 2023
            </Typography>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabsHome
              value={value}
              label={exhibitionLabel}
              handleChange={handleChange}
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{
              paddingBottom: "16px",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Grid item display="flex" direction="column" id="description">
              <Box>
                <Typography fontWeight={"700"} fontSize={"32px"}>
                  Animakini X Ciffest 2023
                </Typography>
              </Box>
              <Box display={"flex"} alignItems={"center"} gap={1}>
                <Icon icon="material-symbols:date-range" />
                <Typography fontWeight={"500"} fontSize={"16px"}>
                  20-22 Februari 2023
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              display="flex"
              direction="column"
              alignItems={"flex-end"}
              gap={1}
            >
              <Typography fontWeight={"500"} fontSize={"12px"}>
                Mulai Dari
              </Typography>
              <Typography
                color={"#D14F1E"}
                fontWeight={"700"}
                fontSize={"24px"}
              >
                IDR 250.000
              </Typography>
              <MainButton
                variant={"contained"}
                onClick={() =>
                  document
                    .getElementById("paket")
                    .scrollIntoView({ behavior: "smooth" }, true)
                }
              >
                Lihat Paket
              </MainButton>
            </Grid>
          </Box>
          <Typography fontWeight={"700"} fontSize={"24px"}>
            Deskripsi
          </Typography>
          <Typography fontWeight={"400"} fontSize={"12px"}>
            Across nine screens, Isaac Julien’s Lina Bo Bardi—A Marvellous
            Entanglement (2019) explores the life, work, and legacy of the
            Italian-Brazilian modernist architect Lina Bo Bardi (1914–1992).
            Born in Rome, Italy, and relocating to São Paulo, Brazil in the
            1940s, Bo Bardi was a multifaceted artist and thinker who developed
            an architectural practice rooted in the social potential of space
            and the fusion of Italian and Brazilian culture and aesthetics.
            Emphasizing improvisation, recycling existing spaces, and utilizing
            novel building materials, Bo Bardi designed some of Brazil’s most
            iconic art and cultural institutions, including the São Paulo Museum
            of Art, the Museum of Modern Art of Bahia, SESC Pompéia, and the
            Teatro Oficina. In a new arrangement imagined by Julien specifically
            for this presentation, A Marvellous Entanglement establishes a vital
            architectural dialogue between Bo Bardi’s visionary buildings and
            the Williams Forum, the central space of Frank Gehry’s expansion
            project. Through a carefully constructed choreography of sound and
            moving images, Julien combines six years of archival research,
            on-location footage shot across multiple Bo Bardi-designed
            buildings, voice and dance performances, and recitations of Bo
            Bardi’s writings by two actresses. Quoting Bo Bardi in his title,
            Julien invokes her spirit and points to the liberatory possibilities
            of non-linear histories within global circulations of art and
            culture. This exhibition is organized in conjunction with multiple
            cultural partners across Philadelphia including the Barnes
            Foundation; BlackStar Projects; Institute of Contemporary Art,
            University of Pennsylvania; and The Fabric Workshop and Museum, on
            the occasion of the Barnes Foundation’s centennial and newly
            commissioned film installation, Once Again…(Statues Never Die) on
            view June 19–September 4, 2022.
          </Typography>
          <Box backgroundColor="#F5F5F5" padding="16px">
            <Box display={"flex"}>
              <img src={Info} alt="" />
              <Typography fontWeight={"700"} fontSize={"14px"}>
                Info Penting
              </Typography>
            </Box>
            <Typography
              fontWeight={"700"}
              fontSize={"12px"}
              color={"#828282"}
              margin="8px 0"
            >
              Waktu dan Tempat
            </Typography>
            <Typography
              width={"40%"}
              fontWeight={"400"}
              fontSize={"12px"}
              color={"#828282"}
            >
              20-22 Februari 2023
            </Typography>
            <Typography
              width={"40%"}
              fontWeight={"400"}
              fontSize={"12px"}
              color={"#828282"}
            >
              Redemption : 12.00 PM (20-22 Februari 2023)
            </Typography>
            <Typography
              width={"40%"}
              fontWeight={"400"}
              fontSize={"12px"}
              color={"#828282"}
            >
              Event Time: 8.00 PM
            </Typography>
            <Typography
              width={"40%"}
              fontWeight={"400"}
              fontSize={"12px"}
              color={"#828282"}
            >
              Open Gate: 8.00 PM
            </Typography>
            <Typography
              width={"40%"}
              fontWeight={"400"}
              fontSize={"12px"}
              color={"#828282"}
              id="paket"
            >
              Venue: Gedung Teater Besar Taman Ismail Marzuki
            </Typography>
          </Box>

          <Box
            sx={{
              paddingTop: "16px",
              borderTop: 1,
              borderColor: "divider",
              fontWeight: "700",
              fontSize: "24px",
            }}
          >
            Paket
          </Box>
          <Box
            sx={{
              paddingTop: "16px",
              borderTop: "1px dashed #828282",
            }}
          >
            <Typography fontWeight="700" fontSize="16px" marginBottom={"16px"}>
              Filter tanggal kunjungan/event
            </Typography>
            <Box display={"flex"} gap={2} marginBottom="16px">
              <Box
                border={1}
                borderColor={"#D14F1E"}
                padding="14px 22.5px"
                textAlign={"center"}
              >
                <Typography fontWeight="700" fontSize="16px" color={"#D14F1E"}>
                  Sen
                </Typography>
                <Typography fontWeight="700" fontSize="16px" color={"#D14F1E"}>
                  20 Feb
                </Typography>
              </Box>
              <Box
                border={1}
                borderColor={"#D14F1E"}
                padding="14px 22.5px"
                textAlign={"center"}
              >
                <Typography fontWeight="700" fontSize="16px" color={"#D14F1E"}>
                  Sen
                </Typography>
                <Typography fontWeight="700" fontSize="16px" color={"#D14F1E"}>
                  20 Feb
                </Typography>
              </Box>
              <Box
                border={1}
                borderColor={"#D14F1E"}
                padding="14px 22.5px"
                textAlign={"center"}
              >
                <Typography fontWeight="700" fontSize="16px" color={"#D14F1E"}>
                  Sen
                </Typography>
                <Typography fontWeight="700" fontSize="16px" color={"#D14F1E"}>
                  20 Feb
                </Typography>
              </Box>
            </Box>
            {/* wrap="no-wrap" makes no new line */}
            <Grid container display={"flex"} gap={2} wrap="no-wrap">
              <Grid
                item
                display="flex"
                direction={"column"}
                width="100%" // no wrap
                gap={2}
              >
                <Grid item>
                  <Box border={1} borderColor={"#828282"} padding="16px">
                    <Typography fontWeight="700" fontSize="20px">
                      VIP
                    </Typography>
                    <Box display="flex" gap={2} paddingBottom={"14px"}>
                      <Box display={"flex"} alignItems={"center"}>
                        <Icon icon="tabler:point-filled" color={"#828282"} />
                        <Typography color={"#828282"}>
                          Tidak bisa refund
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <Icon icon="tabler:point-filled" color={"#828282"} />
                        <Typography color={"#828282"}>
                          Pembayaran manual
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      borderTop={"1px dashed #828282"}
                      paddingTop={"14px"}
                    >
                      <Typography
                        fontWeight="700"
                        fontSize="24px"
                        color="#D14F1E"
                      >
                        IDR 500.000
                      </Typography>
                      <MainButton
                        onClick={() =>
                          window.open("https://www.loket.com/", "_blank")
                        }
                        variant={"contained"}
                      >
                        Pilih Tiket
                      </MainButton>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box border={1} padding="16px" borderColor={"#828282"}>
                    <Typography fontWeight="700" fontSize="20px">
                      VIP
                    </Typography>
                    <Box display="flex" gap={2} paddingBottom={"14px"}>
                      <Box display={"flex"} alignItems={"center"}>
                        <Icon icon="tabler:point-filled" color={"#828282"} />
                        <Typography color={"#828282"}>
                          Tidak bisa refund
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <Icon icon="tabler:point-filled" color={"#828282"} />
                        <Typography color={"#828282"}>
                          Pembayaran manual
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      borderTop={"1px dashed #828282"}
                      paddingTop={"14px"}
                    >
                      <Typography
                        fontWeight="700"
                        fontSize="24px"
                        color="#D14F1E"
                      >
                        IDR 500.000
                      </Typography>
                      <MainButton
                        onClick={() =>
                          window.open("https://www.loket.com/", "_blank")
                        }
                        variant={"contained"}
                      >
                        Pilih Tiket
                      </MainButton>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box border={1} padding="16px" borderColor={"#828282"}>
                    <Typography fontWeight="700" fontSize="20px">
                      VIP
                    </Typography>
                    <Box display="flex" gap={2} paddingBottom={"14px"}>
                      <Box display={"flex"} alignItems={"center"}>
                        <Icon icon="tabler:point-filled" color={"#828282"} />
                        <Typography color={"#828282"}>
                          Tidak bisa refund
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <Icon icon="tabler:point-filled" color={"#828282"} />
                        <Typography color={"#828282"}>
                          Pembayaran manual
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      borderTop={"1px dashed #828282"}
                      paddingTop={"14px"}
                    >
                      <Typography
                        fontWeight="700"
                        fontSize="24px"
                        color="#D14F1E"
                        id="location"
                      >
                        IDR 500.000
                      </Typography>
                      <MainButton
                        onClick={() =>
                          window.open("https://www.loket.com/", "_blank")
                        }
                        variant={"contained"}
                      >
                        Pilih Tiket
                      </MainButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item>
                <Box border={1} padding={"16px"} borderColor={"#828282"}>
                  <Typography
                    fontWeight="700"
                    fontSize="20px"
                    marginBottom={"16px"}
                  >
                    Venue
                  </Typography>
                  <Box>
                    <img src={Pict} alt="" />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Typography fontWeight="700" fontSize="24px">
            Lokasi
          </Typography>

          <Grid
            item
            display="flex"
            flexDirection={"column"}
            border={1}
            borderColor="#828282"
            padding="1rem"
          >
            <Grid item>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.5485007339453!2d106.83615461488672!3d-6.191115162380591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f43ed58a394b%3A0x52eb0c276d813909!2sTaman%20Ismail%20Marzuki!5e0!3m2!1sid!2sid!4v1678857908855!5m2!1sid!2sid"
                style={{
                  border: "0",
                  width: "100%",
                }}
                display="flex"
                height={"260px"}
                allowfullscreen=""
                loading="lazy"
                title="Venue Map"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </Grid>

            <Grid
              item
              display="flex"
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Grid
                item
                display="flex"
                flexDirection={"row"}
                alignItems={"center"}
                gap={2}
              >
                <Grid item>
                  <Icon icon="ph:map-pin" width="24" height="24" />
                </Grid>
                <Grid item display="flex" flexDirection={"column"}>
                  <Grid item fontWeight="500" fontSize="14px">
                    Jl. Cikini Raya No.73, RT.8/RW.2, Cikini, Kec. Menteng, Kota
                    Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10330
                  </Grid>
                  <Grid item color="#828282" fontWeight="500" fontSize="14px">
                    Gedung Taman Ismail Marzuki
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <a href="https://www.google.com/maps/dir//Taman+Ismail+Marzuki+Cikini+Kec.+Menteng+Kota+Jakarta+Pusat,+Daerah+Khusus+Ibukota+Jakarta/@-6.1911205,106.8383433,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x2e69f43ed58a394b:0x52eb0c276d813909!2m2!1d106.8383433!2d-6.1911205">
                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "#D14F1E",
                      borderRadius: "0",
                      padding: "12px 24px",
                    }}
                  >
                    <Grid
                      item
                      display="flex"
                      flexDirection={"row"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <Grid item>
                        <Icon
                          icon="teenyicons:direction-outline"
                          color="#d14f1e"
                          width="14"
                          height="14"
                          rotate={1}
                        />
                      </Grid>
                      <Grid item>Petunjuk Arah</Grid>
                    </Grid>
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Grid>

          <Box display={"flex"} gap={2} marginTop="64px">
            <Typography fontWeight="700" fontSize="24px" color={"#D14F1E"}>
              Exhibitions & Events Lainnya
            </Typography>
            <Grid
              item
              height="2px"
              backgroundColor="#D14F1E"
              width={"80px"}
              marginTop="20px"
            ></Grid>
          </Box>

          <Box display={"flex"} gap={2}>
            <Card photo={Event1} />
            <Card photo={Event2} />
            <Card photo={Event3} />
            <Card photo={Event4} />
          </Box>
        </Grid>
        <Fotter />
      </Box>
    </>
  );
};

export default About;
