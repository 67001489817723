import React from "react";
import Card from "./Card";
import { Grid } from "@mui/material";

const CarouselList = ({ data, onClick, stlye }) => {
  return (
    <>
      <Grid display={"flex"} direction="row" gap={1} padding="0 4rem">
        {Object.values(data)?.map((item, i) => (
          <Card onClick={onClick} stlye={stlye} photo={item} key={i} />
        ))}
      </Grid>
    </>
  );
};

export default CarouselList;
