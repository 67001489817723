import React from "react";
import { Grid, Box } from "@mui/material";

const Card = ({ photo, onClick, stlye }) => {
  return (
    <>
      <Grid item onClick={onClick} sx={stlye}>
        <Box
          sx={{
            height: "50vh",
            backgroundImage: `url(${photo})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></Box>
        <Grid
          item
          width="90%"
          display={"flex"}
          direction="row"
          border="1"
          backgroundColor="white"
          // zIndex={"1"}
          position="relative"
          top="-70px"
          margin="auto"
          sx={{ boxShadow: 1 }}
          padding="15px"
          justifyContent={"space-between"}
        >
          <Grid
            item
            display={"flex"}
            direction="column"
            margin="auto 0"
            gap={1.5}
          >
            <Grid item fontWeight="700" fontSize="16px">
              Animakini X Ciffest 2018
            </Grid>
            <Grid item color="#828282" fontWeight="400" fontSize="12px">
              17 Agustus 2024
            </Grid>
            <Grid item color="#1F1F1F" fontWeight="400" fontSize="10px">
              Experience Sir Isaac Julien’s video installation exploring the
              life and legacy of architect Lina Bo Bardi.
            </Grid>
          </Grid>
          <Grid item margin="auto">
            <Box
              sx={{
                backgroundColor: "#FFF1EB",
                color: "#FF723D",
                borderRadius: "50%",
                padding: "17.5444px 17px",
                textAlign: "center",
                fontWeight: "700",
                fontSize: "14px",
              }}
            >
              Free
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Card;
